* {
	box-sizing: border-box;
}

@font-face {
	font-family: 'Relative-Medium-Pro';
	src: url('/fonts/Relative-Medium-Pro.woff2') format('woff2'),
		url('/fonts/Relative-Medium-Pro.woff') format('woff');
	font-display: sans-serif;
}

@font-face {
	font-family: 'Relative-Mono-Pro';
	src: url('/fonts/relative-mono-10-pitch-pro.woff2') format('woff2'),
		url('/fonts/relative-mono-10-pitch-pro.woff') format('woff');
	font-display: sans-serif;
}

@font-face {
	font-family: 'Relative-Mono';
	src: url('/fonts/relative-mono-10-pitch-pro.woff2') format('woff2'),
		url('/fonts/relative-mono-10-pitch-pro.woff') format('woff');
	font-display: sans-serif;
}

@font-face {
	font-family: 'Relative-Book-Pro';
	src: url('/font/Relative-Book-Pro.woff2') format('woff2'),
		url('/fonts/Relative-Book-Pro.woff') format('woff');
	font-display: sans-serif;
}

@font-face {
	font-family: 'Relative-Black-Pro';
	src: url('/fonts/Relative-Black-Pro.woff2') format('woff2'),
		url('/fonts/Relative-Black-Pro.woff') format('woff');
	font-display: sans-serif;
}

@font-face {
	font-family: 'Relative-Bold-Pro';
	src: url('/fonts/Relative-Bold-Pro.woff2') format('woff2'),
		url('/fonts/Relative-Bold-Pro.woff') format('woff');
	font-display: sans-serif;
}

@font-face {
	font-family: 'AkkuratMonoLLWeb-Regular';
	src: url('/fonts/AkkuratMonoLLWeb-Regular.woff2') format('woff2'),
		url('/fonts/AkkuratMonoLLWeb-Regular.woff') format('woff');
	font-display: monospace;
}

@font-face {
	font-family: 'AkkuratMonoLLWeb-Bold';
	src: url('/fonts/AkkuratMonoLLWeb-Bold.woff2') format('woff2'),
		url('/fonts/AkkuratMonoLLWeb-Bold.woff') format('woff');
	font-display: monospace;
}

@font-face {
	font-family: 'GT-America-Compressed-Black';
	src: url('/fonts/GT-America-Compressed-Black.woff2') format('woff2'),
		url('/fonts/GT-America-Compressed-Black.woff') format('woff');
	font-display: auto;
}

@font-face {
	font-family: 'GT-America-Compressed-Medium';
	src: url('/fonts/GT-America-Compressed-Medium.woff2') format('woff2'),
		url('/fonts/GT-America-Compressed-Medium.woff') format('woff');
	font-display: auto;
}

#__next {
	width: 100%;
	height: 100%;
}

#__next > div {
	height: 100%;
}

* {
	scroll-behavior: smooth;
}

html {
	scrollbar-width: none;
}

html,
body {
	height: 100%;
	min-height: 100%;
}

body {
	font-family: 'Relative-Medium-Pro', -apple-system, BlinkMacSystemFont, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeSpeed;
	margin: 0;
	position: relative;
	font-size: 12px;
	font-feature-settings: 'zero' 1;

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow: hidden;
}

::-webkit-scrollbar {
	display: none;
	width: 0;
	height: 0;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.Toastify__toast-container--top-right {
	top: 1em;
	right: -1.5rem;
}
